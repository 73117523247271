
export default {
  name: 'VSticky',

  props: {
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    headerSelector: {
      type: String,
      default: '.header-bottom',
    },
  },
  data() {
    return {
      sticky: {},
      defaultOptions: {
        margin: 24,
        marginHeader: true,
      },

      headerHeight: 0,

      topOffset: 0,
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    $sticky() {
      return this.$refs.sticky
    },
  },
  created() {
    Object.assign(this.sticky, this.defaultOptions, this.options)
  },
  mounted() {
    window.addEventListener('scroll', this.handleSideBarScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleSideBarScroll);
  },
  methods: {
    handleSideBarScroll() {
      if (this.sticky.marginHeader) {
        this.headerHeight = this.getHeaderHeight()
      }

      if (this.$sticky.clientHeight > (window.innerHeight - this.headerHeight - this.sticky.margin)) {
        const scrollHeight = Math.floor(pageYOffset - this.lastScrollY) || 0;
        const maxTop = this.$sticky.clientHeight - window.innerHeight

        if (pageYOffset > this.lastScrollY ) {
          if ((this.topOffset += scrollHeight) > maxTop) {
            this.topOffset = maxTop
          }

          this.$sticky.style.top = `${this.topOffset * -1 - this.sticky.margin}px`
        } else {
          if ((this.topOffset += scrollHeight) < -this.headerHeight - this.sticky.margin) {
            this.topOffset = -this.headerHeight - this.sticky.margin
          }

          this.$sticky.style.top = `${this.topOffset * -1}px`
        }

        this.lastScrollY = pageYOffset;

        return
      }

      this.$sticky.style.top = ''
    },
    getHeaderHeight() {
      if (!this.headerSelector) return 0

      const $header = document.querySelector(this.headerSelector)

      if ($header) {
        return $header.clientHeight
      }

      return 0;
    },
  },
}
