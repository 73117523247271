
import Constructor from '~/components/catalog/constructor';
import CustomSidebar from '~/components/elements/CustomSidebar';
import VSticky from '~/components/elements/VSticky/VSticky';

export default {
  name: 'SidebarWrapper',
  components: {CustomSidebar, Constructor, VSticky},
  props: {
    sidebar: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    sidebarWidth: {
      type: Number,
      default: 248,
    },
    sidebarPos: {
      type: String,
      default: 'left',
    },
  },
}
